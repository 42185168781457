/*------------------------------------------------------------------
[Table of contents]

1. THEME COLORS
    1.1 PRIMARY COLORS
    1.2 SECONDARY COLORS
    1.3 TERTIARY COLORS
2. GENERAL COLORS
    2.1 BODY COLORS
    2.2 BOX COLORS
    2.3 TEXT COLORS
    2.4 TEXT SELECTION COLORS
    2.5 LINK COLORS
    2.6 ICON COLORS
    2.7 BORDER COLORS
    2.8 SHADOW COLORS
    2.9 INPUT COLORS
    2.10 BUTTON COLORS
    2.11 PAGE LOADER
    2.12 LOADER BAR
3. COMPONENT COLORS
    3.0 TAB SWITCH
    3.1 FORM SWITCH
    3.2 USER STATUS
    3.3 VIDEO STATUS
    3.4 SECTION MENU
    3.5 CHAT WIDGET
    3.6 NAVIGATION WIDGET
    3.7 XM TOOLTIP TEXT
    3.8 DROPDOWN LIST ITEM
    3.9 PICTURE ITEM
    3.10 TEXT STICKER
    3.11 TAG STICKER
    3.12 DATE STICKER
    3.13 FORUM POST
    3.14 ALBUM PREVIEW
    3.15 PICTURE COLLAGE
    3.16 STATUS COLORS
    3.17 PATTERN COLORS
    3.18 ACCOUNT STAT COLORS
    3.19 STAT COLORS
    3.20 ACTIVITY COLORS
    3.21 CATEGORY COLORS
    3.22 REFERENCE BULLET COLORS
-------------------------------------------------------------------*/
/*-------------------------------------------------------------------
                        1. THEME COLORS
-------------------------------------------------------------------*/
/*-------------------------
    1.1 PRIMARY COLORS
-------------------------*/
$color-primary: $cyan;
$color-primary-background: $cyan;
$color-primary-dark: $cyan-dark;
$color-primary-light: $cyan-light;
$color-primary-shadow: 4px 7px 12px 0 rgba(35, 210, 226, .2);
$color-primary-hover: $cyan-hover;

/*---------------------------
    1.2 SECONDARY COLORS
---------------------------*/
$color-secondary: $violet;
$color-secondary-dark: $violet-dark;
$color-secondary-darker: $violet-darker;
$color-secondary-shadow: 4px 7px 12px 0 rgba(97, 93, 250, .2);
$color-secondary-hover: $violet-hover;

/*---------------------------
    1.3 TERTIARY COLORS
---------------------------*/
$color-tertiary: $red-dark-mid;
$color-tertiary-shadow: 4px 7px 12px 0 rgba(253, 67, 80, .2);
$color-tertiary-hover: $red-hover;

/*-------------------------------------------------------------------
                        2. GENERAL COLORS
-------------------------------------------------------------------*/
/*-----------------------
    2.1 BODY COLORS
-----------------------*/
$color-body: #f8f8fb;
$color-body-underlay: $white;

/*-----------------------
    2.2 BOX COLORS
-----------------------*/
$color-box-background: $white;
$color-box-background-alt: $white-dark;
$color-box-over-box-background: $white;
$color-box-over-box-light-background: $white;

/*-----------------------
    2.3 TEXT COLORS
-----------------------*/
$color-text: $black;
$color-text-2: $black;
$color-text-3: $black-dark-mid;
$color-text-alt: $gray-dark;
$color-text-alt-2: $gray;

/*-------------------------------
    2.4 TEXT SELECTION COLORS
-------------------------------*/
$color-text-selection: $color-primary-background;

/*--------------------------
    2.5 LINK COLORS
--------------------------*/
$color-text-link: $color-primary-dark;

/*-----------------------
    2.6 ICON COLORS
-----------------------*/
$color-icon: $gray;
$color-icon-dark: #8b88ff;
$color-icon-bold: $black;
$color-rating-icon: $gray-lighter;
$color-rating-icon-dark: $gray-dark;

/*-----------------------
    2.7 BORDER COLORS
-----------------------*/
$color-divider: $gray-lighter;
$color-border: $gray-light;
$color-border-dark: #7a77fd;

/*-----------------------
    2.8 SHADOW COLORS
-----------------------*/
$shadow: 3px 5px 20px 0 rgba(94, 92, 154, .1);
$shadow-alt: 0 0 40px 0 rgba(94, 92, 154, .1);
$shadow-alt-2: 3px 5px 40px 0 rgba(94, 92, 154, .1);

$shadow-light: 0 0 40px 0 rgba(94, 92, 154, .06);
$shadow-light-alt: 3px 5px 40px 0 rgba(94, 92, 154, .06);

$shadow-dark: 0 0 40px 0 rgba(94, 92, 154, .12);
$shadow-dark-alt: 3px 5px 20px 0 rgba(94, 92, 154, .12);
$shadow-dark-alt-2: 2px 3px 10px 0 rgba(94, 92, 154, .12);
$shadow-dark-alt-3: 0 0 60px 0 rgba(94, 92, 154, .12);

$shadow-darker: 3px 5px 20px 0px rgba(94, 92, 154, .16);

/*-----------------------
    2.9 INPUT COLORS
-----------------------*/
$color-input-placeholder: $gray;
$color-input-placeholder-dark: #8b88ff;

/*------------------------------
    2.10 BUTTON
------------------------------*/
$color-button-background: $black;
$color-button-background-hover: $black-dark;
$color-button-shadow: 3px 5px 10px 0 rgba(62, 63, 94, .2);
$color-button-white-background-hover: $black-dark;
$color-button-void-background-hover: $black-dark;

/*-----------------------
    2.11 PAGE LOADER
-----------------------*/
$color-page-loader-background: $black;

/*-----------------------
    2.12 LOADER BAR
-----------------------*/
$color-loader-bar-1: var(--primary);
$color-loader-bar-2: #5d71fb;
$color-loader-bar-3: #5983fb;
$color-loader-bar-4: #549afc;
$color-loader-bar-5: #4eb2fd;
$color-loader-bar-6: #49c9fe;
$color-loader-bar-7: #45ddfe;
$color-loader-bar-8: #41efff;

/*-------------------------------------------------------------------
                        3. COMPONENT COLORS
-------------------------------------------------------------------*/
/*---------------------------
    3.0 TAB SWITCH
---------------------------*/
$color-tab-switch-border: $white;
$color-tab-switch-text: $white;
$color-tab-switch-active-text: $black;
$color-tab-switch-active-background: $white;

/*---------------------------
    3.1 FORM SWITCH
---------------------------*/
$color-form-switch-background: $gray-lighter;
$color-form-switch-button: $gray;

/*-----------------------
    3.2 USER STATUS
-----------------------*/
$color-user-status-text: $color-text;

/*-----------------------
    3.3 VIDEO STATUS
-----------------------*/
$color-video-status-meta: $color-text-alt-2;

/*-------------------------
    3.4 SECTION MENU
-------------------------*/
$color-section-menu-item-icon-primary-active: $black;
$color-section-menu-item-icon-secondary-active: $black;

/*-------------------------
    3.5 CHAT WIDGET
-------------------------*/
$color-chat-widget-message-hover: $gray-lighter;
$color-chat-widget-close-button: $black;
$color-chat-widget-conversation-background: $white-dark;
$color-chat-widget-speaker-message: $white-darker;

/*---------------------------
    3.6 NAVIGATION WIDGET
---------------------------*/
$color-navigation-widget-close-button: $black;

/*---------------------------
    3.7 XM TOOLTIP TEXT
---------------------------*/
$color-tooltip-background: $black;

/*---------------------------
    3.8 DROPDOWN LIST ITEM
---------------------------*/
$color-dropdown-list-item-hover: $gray-lighter;

/*---------------------------
    3.9 PICTURE ITEM
---------------------------*/
$color-picture-item-overlay: rgba(36, 210, 226, .9);

/*---------------------------
    3.10 TEXT STICKER
---------------------------*/
$color-text-sticker-background: $white;
$color-text-sticker-negative-background: $black-darker;

/*---------------------------
    3.11 TAG STICKER
---------------------------*/
$color-tag-sticker-background: $white;
$color-tag-sticker-icon: $black;
$color-tag-sticker-icon-primary: $color-primary;

/*---------------------------
    3.12 DATE STICKER
---------------------------*/
$color-date-sticker-day-background: $black;

/*-------------------------------
    3.13 FORUM POST
-------------------------------*/
$color-forum-post-user-tag-background: $color-secondary-darker;
$color-forum-post-user-tag-organizer-background: $color-secondary;

/*------------------------------
    3.14 ALBUM PREVIEW
------------------------------*/
$color-album-preview-shadow: rgba(35, 210, 226, .9);

/*------------------------------
    3.15 PICTURE COLLAGE
------------------------------*/
$color-picture-collage-overlay-background: rgba(35, 210, 226, .9);

/*-------------------------
    3.16 STATUS COLORS
-------------------------*/
$color-online: $green;
$color-away: $gray;
$color-offline: $red;
$color-stream-live: $red-darker;

/*-------------------------
    3.17 PATTERN COLORS
-------------------------*/
$color-pattern-1: $violet;
$color-pattern-2: $blue;
$color-pattern-3: $light-blue;
$color-pattern-4: $cyan;

/*-----------------------------
    3.18 ACCOUNT STAT COLORS
-----------------------------*/
$color-account-stat-active-users: $color-pattern-1;
$color-account-stat-visits: $color-pattern-2;
$color-account-stat-session-duration: $color-pattern-3;
$color-account-stat-returning-visitors: $color-pattern-4;

/*-----------------------
    3.19 STAT COLORS
-----------------------*/
$color-stat-item: $color-pattern-1;
$color-stat-earning: $color-pattern-2;
$color-stat-revenue: $color-pattern-3;
$color-stat-balance: $color-pattern-4;

/*--------------------------
    3.20 ACTIVITY COLORS
--------------------------*/
$color-activity-reaction: $color-pattern-1;
$color-activity-comment: $color-pattern-2;
$color-activity-share: $color-pattern-3;
$color-activity-update: $color-pattern-4;

/*--------------------------
    3.21 CATEGORY COLORS
--------------------------*/
$gradient-category-all: linear-gradient(to right, var(--primary), var(--secondary));
$gradient-category-featured: linear-gradient(to right, #417ae1, #5aafff);
$gradient-category-digital: linear-gradient(to right, #2ebfef, #4ce4ff);
$gradient-category-physical: linear-gradient(to right, #17cada, #2becfe);

$color-category-all: $color-pattern-1;
$color-category-featured: $color-pattern-2;
$color-category-digital: $color-pattern-3;
$color-category-physical: $color-pattern-4;

/*------------------------------------
    3.22 REFERENCE BULLET COLORS
------------------------------------*/
$color-reference-bullet-reactions: $color-secondary;
$color-reference-bullet-comments: $color-primary;
$color-reference-bullet-shares: $blue;
$color-reference-bullet-replies: $light-blue;