.demo-box {
  padding: 48px 28px 32px;
  border-radius: 12px;
  background-color: $color-box-background;
  box-shadow: $shadow-light;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 48px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: $color-secondary;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:nth-child(2n+2) {
    &::before {
      background-color: $color-primary-background;
    }
  }

  .demo-box-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    background-color: $color-box-background;
    margin: -26px auto 0;
    position: relative;
    z-index: 2;

    .demo-box-icon {
      fill: $color-icon-bold;
    }
  }

  .demo-box-title,
  .demo-box-text {
    text-align: center;
  }

  .demo-box-title {
    margin-top: 8px;
    font-size: $fs-16;
    font-weight: $weight-bold;
  }

  .demo-box-text {
    margin-top: 4px;
    font-size: $fs-12;
    font-weight: $weight-medium;
  }

  .button {
    margin-top: 36px;
    overflow: hidden;
    position: relative;

    .active-text,
    .inactive-text {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform .3s ease-in-out;
    }

    .active-text {
      transform: translate(0, 100%);
    }

    &.active {
      background-color: $color-primary-background;

      .inactive-text {
        transform: translate(0, -100%);
      }

      .active-text {
        transform: translate(0, 0);
      }
    }
  }
}